import React, { useEffect } from 'react'
import SEO from 'components/SEO'
import Layout from 'components/Layout'

const report = '/docs/Entregador-2-versao.pdf'

const PrivacyCouriersPDF = () => {
  useEffect(() => {
    if (window) {
      window.location = report
    }
  })
  return (
    <Layout>
      <SEO
        description="Privacidade entregador PDF válido de 09/04/2021 até 17/08/2022"
        title="Privacidade entregador PDF"
      />
    </Layout>
  )
}

export default PrivacyCouriersPDF
